import React from 'react';
import styled from 'styled-components';
import { BaseButton, BaseButtonWrapper } from '.';
import { enums } from '../../lib/nvstr-utils.es';

export const ButtonWrap = styled.div`
  ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};

  ${BaseButtonWrapper} {
    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
      fill: ${({ theme }) => theme.themeColors.buttonText};
      line-height: 1;
    }
    ${({ width }) => (width ? 'width: ' + width : '')};
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    border: ${({ theme }) => '1px solid ' + theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
    fill: ${({ theme }) => theme.themeColors.buttonText};

    ${({ small, smallNoVert }) => (smallNoVert ? `padding: 0px 8px` : small ? `padding: 4px 8px` : '')};

    :hover {
      opacity: 0.8;
    }
  }
`;
export const TransparentButtonWrap = styled.div`
  ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};

  ${BaseButtonWrapper} {
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};
    background: none;
    color: ${({ theme, color }) => (color ? color : theme.themeColors.text)};
    fill: ${({ theme, color }) => (color ? color : theme.themeColors.text)};
    border: none;

    ${({ small, smallNoVert }) => (smallNoVert ? `padding: 0px 8px` : small ? `padding: 4px 8px` : '')};

    * {
      fill: ${({ theme, color }) => (color ? color : theme.themeColors.text)};
      color: ${({ theme, color }) => (color ? color : theme.themeColors.text)};
      line-height: 1;
    }

    :hover {
      opacity: 0.8;
    }
  }
`;
export const StaticColorButtonWrap = styled.div`
  ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};

  ${BaseButtonWrapper} {
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};
    background: ${({ color }) => color};
    border: 1px solid ${({ color }) => color};
    * {
      color: ${({ textColor }) => textColor};
      line-height: 1;
    }
    :hover {
      opacity: 0.8;
    }
  }
`;
export const SecondaryButtonWrap = styled.div`
  ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};
  
  ${BaseButtonWrapper} {
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};
    background: ${({ theme }) => theme.themeColors.text};
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    border:  1px solid ${({ theme }) => theme.themeColors.text};
    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
      line-height: 1;
    }
    :hover {
      opacity: 0.8;
    }
`;
export const DisabledButtonWrap = styled.div`
  ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')}

  ${BaseButtonWrapper} {
    width: ${({ width }) => (width ? width : 'auto')};
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')}
    background: ${({ theme, transparent }) => (transparent ? 'none' : theme.themeColors.primaryCtaButton)};
    border: ${({ theme, transparent }) => (transparent ? 'none' : '1px solid ' + theme.themeColors.primaryCtaButton)};
    color: ${({ theme, transparent, color }) =>
      transparent ? (color ? color : theme.themeColors.text) : color ? color : theme.themeColors.buttonText};
    * {
      color: ${({ theme, transparent, color }) =>
        transparent ? (color ? color : theme.themeColors.text) : color ? color : theme.themeColors.buttonText};
      fill: ${({ theme, transparent, color }) =>
        transparent ? (color ? color : theme.themeColors.text) : color ? color : theme.themeColors.buttonText};
      line-height: 1;
    }
    opacity: 0.45;
    cursor: not-allowed;

    :hover {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
`;

export const FlatButton = (props) => {
  const {
    className,
    children,
    buttonProps,
    secondary,
    color,
    textColor,
    disabled,
    transparent,
    fullWidth,
    width,
    small,
    smallNoVert,
  } = props;
  const onClick = props.onClick || props.handleClick;

  if (disabled) {
    return (
      <DisabledButtonWrap transparent={transparent} width={width} fullWidth={fullWidth}>
        <BaseButton
          className={className || ''}
          type="button"
          buttonType={enums.button_types.button}
          fullWidth={fullWidth}
          {...buttonProps}
          onClick={onClick}
        >
          <b>{children}</b>
        </BaseButton>
      </DisabledButtonWrap>
    );
  }
  if (transparent) {
    return (
      <TransparentButtonWrap color={color} fullWidth={fullWidth} small={small} smallNoVert={smallNoVert}>
        <BaseButton
          className={className || ''}
          type="button"
          buttonType={enums.button_types.button}
          fullWidth={fullWidth}
          {...buttonProps}
          onClick={onClick}
        >
          <b>{children}</b>
        </BaseButton>
      </TransparentButtonWrap>
    );
  }
  if (secondary) {
    return (
      <SecondaryButtonWrap fullWidth={fullWidth}>
        <BaseButton
          className={className || ''}
          type="button"
          buttonType={enums.button_types.button}
          fullWidth={fullWidth}
          {...buttonProps}
          onClick={onClick}
        >
          <b>{children}</b>
        </BaseButton>
      </SecondaryButtonWrap>
    );
  }
  if (color) {
    return (
      <StaticColorButtonWrap color={color} textColor={textColor} fullWidth={fullWidth}>
        <BaseButton
          className={className || ''}
          type="button"
          buttonType={enums.button_types.button}
          fullWidth={fullWidth}
          {...buttonProps}
          onClick={onClick}
        >
          <b>{children}</b>
        </BaseButton>
      </StaticColorButtonWrap>
    );
  }
  return (
    <ButtonWrap width={width} fullWidth={fullWidth} small={small} smallNoVert={smallNoVert}>
      <BaseButton
        className={className || ''}
        type="button"
        buttonType={enums.button_types.button}
        fullWidth={fullWidth}
        {...buttonProps}
        onClick={onClick}
      >
        <b>{children}</b>
      </BaseButton>
    </ButtonWrap>
  );
};

FlatButton.defaultProps = {
  buttonProps: {},
};
